<template>
  <div class="card">
    <div class="card-body">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <div class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="$router.push({name: 'start-page', params: {id:'new'}})"
          >
            Новый старт
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Поиск"
              type="search"
              class="d-inline-block"
              @update="(search, loading) => getStarts(search, loading)"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <router-link
                  :to="{name: 'start-page', params:{id:props.row.id}}"
                  class="edit-button"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Редактировать</span>
                </router-link>

                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click="startToDelete=props.row.id"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'main'">
            <b-form-checkbox
              v-model="props.row.on_main_page"
              checked="true"
              class="custom-control-primary"
              name="check-view"
              switch
              @change="switchStartMainPage(props.row, props.row.on_main_page)"
            />
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <!-- <div slot="no-more"></div>
        <div slot="no-results"></div> -->
      </infinite-loading>
    </div>

    <div>
      <!-- button trigger -->
      <div class="demo-inline-spacing" />
      <!-- modal -->
      <b-modal
        id="modal-primary"
        v-model="modalIsOpen"
        ok-only
        ok-title="Отмена"

        modal-class="modal-primary"
        centered
        title="Primary Modal"
      >
        <div>Вы действительно хотите удалить старт?</div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="primary"
          @click="deleteItem(startToDelete)"
        >
          Удалить
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crudModule from '../../libs/crud_module'
import ToastificationContent from '../../@core/components/toastification/ToastificationContent'

const startModule = crudModule({
  entity_name: 'start',
})

export default {
  components: {
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    InfiniteLoading,
    BFormCheckbox,
    // ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      debounce: null,
      scrollState: undefined,
      skipCountStarts: 20,
      maxResultCountStarts: 20,
      modalIsOpen: false,
      startToDelete: null,
      columns: [
        {
          label: 'На главной?',
          field: 'main',
        },
        {
          label: 'Название старта',
          field: 'name',
        },
        {
          label: 'Статус',
          field: 'email',
        },
        {
          label: 'Дата старта',
          field: rowObj => this.formatDate(rowObj.start_date),
        },
        {
          label: 'Дата регистрации',
          field: rowObj => this.formatDate(rowObj.registration_start_date),
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
      pageLength: '',
      dir: false,
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  async created() {
    try {
      const payload = {
        skipCount: 0,
        maxResultCount: this.maxResultCountStarts,
        sorting: "createdAt DESC"
      }
      this.rows = await startModule.fetchItems(payload)
        .then(res => res.data.rows)
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    switchStartMainPage(start, value) {
      startModule.updateItemPatch(start.id, {
        on_main_page: value,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Изменено',
              icon: 'BellIcon',
              text: 'Старт изменён',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Скопировано',
              icon: 'BellIcon',
              text: e.response.data.message,
              variant: 'warning',
            },
          })
        })
    },
    async infiniteScroll($state) {
      this.scrollState = $state
      const payload = {
        skipCount: this.skipCountStarts,
        maxResultCount: this.maxResultCountStarts,
        filterText: this.searchTerm,
      }
      await startModule.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCountStarts += this.maxResultCountStarts
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      await startModule.deleteItem(id)
      this.rows = await startModule.fetchItems().then(r => r.data.rows)
      this.modalIsOpen = false
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date)
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const newStr = newDate.toLocaleDateString('ru', options)
        return newStr
      }
      return ''
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    getStarts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        this.skipCountStarts = 20
        try {
          await startModule.fetchItems({
            filterText: search,
            skipCount: 0,
            maxResultCount: this.maxResultCountStarts,
          })
            .then(result => {
              this.rows = result.data.rows
              if (this.scrollState) {
                this.scrollState.reset()
              }
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
  .edit-button {
    padding: 0.65rem 1.28rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(115, 103, 240, 0.12);
    }
  }
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
